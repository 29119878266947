<template>
  <div
    class="page-out"
    :class="isPcMobileClass()"
  >
    <div class="img-box">
      <img
        src="@/assets/img/icon_tips_success@2x.png"
        alt=""
      >
    </div>
    <div class="page-title">
      {{ '工商变更申请提交成功' }}
    </div>
  </div>
</template>

<script>
import { isPcMobile } from '@/lib/until.js';
export default {
  methods: {
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/components/btn-bottom.scss';
.page-out{
  overflow: hidden;
  background-color: $color-FFF;
  .img-box{
    width: 60px;
    height: 60px;
    margin: 80px auto;
    margin-bottom: 24px;
    img {
      width: 60px;
    }
  }
  .page-title{
    margin: 0 52px;
    color: #23252E;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
